<template>
    <!-- 持续滚动，鼠标悬停停止组件 -->
    <div class="main">
        <div ref="sl">
            <slot></slot>
            <slot></slot>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SlickCarousel",
    props: {
        // 方向
        direction: String,
    },
    data() {
        return {
            timer: null,
            div: {},
            w: 0,
        };
    },
    mounted() {
        const div = this.$refs["sl"];
        // let html = div.innerHTML;
        let w = div.offsetWidth;
        this.div = div;
        this.w = w / 3;
        // w==2160
        // div.innerHTML = html + html + html;
        div.style.left = `-${w / 3}px`;
        this.start();
    },
    methods: {
        // 停止
        stop() {
            clearInterval(this.timer);
        },
        // 开始
        start() {
            let div = this.div;
            let w = this.w;
            this.timer = setInterval(() => {
                let str = div.style.left;
                let x = Number(str.match(/-?\d+/g)[0]);
                if (this.direction == "left") {
                    if (x <= -2 * w) x = 0;
                    div.style.left = `${--x}px`;
                } else if (this.direction == "right") {
                    if (x >= 0) x = -2 * w;
                    div.style.left = `${++x}px`;
                }
            }, 20);
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100px;
    & > div {
        position: absolute;
        top: 0px;
        // display: flex;
        white-space: nowrap;
        & > div {
            display: inline-block;
        }
    }
}
</style>

<template>
    <div style="font-size: 16px" v-if="items.length">
        <!-- head开始 -->
        <div class="head">
            <div class="head-one">
                <h1 style="margin-bottom: 1rem">商品定价</h1>
                <p>可根据你的企业规模和需求，选择不同的产品、版本和部署方式</p>
            </div>
            <div class="head-two w">
                <div class="version up" v-for="item in items" :key="item.id" v-show="item.status">
                    <div>
                        <div class="tip" v-show="item.hot_status">
                            <div v-show="item.hot">{{ item.hot }}</div>
                        </div>
                        <div class="version-one">
                            <div>
                                <h3>{{ item.name }}</h3>
                                <p class="m-t">{{ item.reason }}</p>
                            </div>
                            <div>
                                <p class="price">
                                    <span>{{ item.oldprice }}</span>
                                    <span> 优惠后</span>
                                    <span>{{ item.newprice }}</span>
                                </p>
                                <div class="m-t">
                                    <el-row>
                                        <button @click="$router.push('/fixPriceDetails?id=' + item.id)">
                                            <span
                                                class="loding"
                                                :style="{
                                                    width: isMout
                                                        ? `${((item.all_time - item.much_time) / item.all_time) * 100}%`
                                                        : '',
                                                }"
                                                v-show="item.hot_status"
                                            ></span>
                                            <template v-if="item.hot_status">
                                                活动截止{{ item.hot_end_time }}，只剩{{ item.much_time }}天
                                            </template>
                                            <template v-else>了解详情</template>
                                        </button>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                        <div class="version-two">
                            <div class="m-t" style="font-weight: 600">{{ item.title }}</div>
                            <div class="">
                                <div class="com m-t" v-for="el in item.content.split('-')" :key="el">
                                    <div>
                                        <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                                    </div>
                                    <span>{{ el }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 续费·专区 -->
        <div class="xu">
            <h1>续费·专区</h1>
            <div>
                <div>
                    <p>会员中心</p>
                    <p>加入会员<a href="#">立即前往></a></p>
                </div>
                <div>
                    <p>续费5.5折起</p>
                    <p>续费5.5折起<a href="#">立即前往></a></p>
                </div>
                <div>
                    <p>续费5.5折起</p>
                    <p>续费5.5折起<a href="#">立即前往></a></p>
                </div>
                <div>
                    <p>续费5.5折起</p>
                    <p>续费5.5折起<a href="#">立即前往></a></p>
                </div>
                <div>
                    <button>续费专区</button>
                </div>
            </div>
        </div>

        <!-- arrange开始 -->
        <div class="arrange w">
            <h1 class="text-c">威有已为数百家百人以上规模的研发团队提供完整的部署方案</h1>
            <div class="arrange-com">
                <div class="arrange-d up" v-for="e in arr" :key="e.id">
                    <div class="arrange-img">
                        <img src="https://iv.okvu.cn/vugw/img/private_solutionX3.png" alt="" />
                    </div>
                    <div class="arrange-ml">
                        <div><b>私有化部署</b></div>
                        <div class="m-t" v-for="item in 4" :key="item"><b>· </b> 数据完整隔离并可直接访问</div>
                    </div>
                </div>
            </div>
            <div class="arrange-bot">
                <button class="arrange-btn">
                    <span>预约演习</span>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ones-icon ones-icon-non-scaling-stroke"
                        stroke-width="2"
                    >
                        <path d="M13.501 8H1M8.605 12.87 13.501 8 8.605 3.13" stroke="currentColor"></path>
                    </svg>
                </button>
            </div>
        </div>

        <!-- service开始 -->
        <div class="service w text-c">
            <h1>更专业的客户服务</h1>
            <p class="m-t">ONES 在全国多地设分公司，产品服务和使用问题均能高效响应，减少客户学习与培训成本</p>
            <div class="service-com">
                <div class="service-d up" v-for="e in arr2" :key="e.id">
                    <img src="https://iv.okvu.cn/vugw/img/connect-scene-img-1.png" alt="" />
                    <div>
                        <h3>客户培训</h3>
                        <p>为用户量身定制可执行、可落地的解决方案，提供场景案例分享与答疑</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- problem问答 -->
        <div class="problem w">
            <h1 class="text-c">购买常见问题</h1>
            <div class="problem-com" v-if="ask.children">
                <div class="problem-d" :class="{ active: e.id == activeId }" v-for="e in ask.children" :key="e.id">
                    <div class="problem-d-t" @click="show(e)">
                        <div>{{ e.answer }}</div>
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="ones-icon ones-icon-non-scaling-stroke oac-w-[16px] oac-h-[16px] oac-text-gray-120 group-hover:oac-text-blue ones-collapse-arrow"
                            stroke-width="2"
                        >
                            <path d="M4.178 5.766 8 9.588l3.82-3.822" stroke="currentColor"></path>
                        </svg>
                    </div>
                    <div class="problem-d-b m-t">
                        {{ e.ask }}
                    </div>
                </div>
            </div>
        </div>

        <!-- authentication开始 -->
        <div class="authentication w text-c">
            <h1>获得多项专业认证，ONES 值得信任</h1>
            <p class="m-t">凭借规范的管理、优秀的产品质量及稳定的技术服务能力，ONES 获得多个权威认证</p>
            <div class="authentication-com">
                <div class="authentication-d up" v-for="e in arr3" :key="e.id">
                    <div>
                        <img src="https://iv.okvu.cn/vugw/img/ISOSOC2.svg" alt="" />
                    </div>
                    <div>SOC2 Type1</div>
                    <div>安全审计报告</div>
                </div>
            </div>
        </div>

        <!-- 滚动图片 -->
        <div class="bannerImgs" v-if="iconList.length">
            <h1>行业头部企业都在选择威有</h1>
            <div v-for="item in 3" :key="'bannerImgsItem' + item">
                <SlickCarousel
                    :direction="item == 2 ? 'right' : 'left'"
                    :ref="'SlickCarousel' + item"
                    @mouseenter.native="$refs['SlickCarousel' + item][0].stop()"
                    @mouseleave.native="$refs['SlickCarousel' + item][0].start()"
                >
                    <div
                        v-for="el in iconList.slice((item - 1) * 12, item * 12)"
                        :key="Math.random() + el.id"
                        class="sid"
                        @click="goDetails(el)"
                    >
                        <img v-lazy="el.icon" />
                        <!-- <p>查看更多案例</p> -->
                        <p>{{ el.title }}</p>
                    </div>
                </SlickCarousel>
            </div>
        </div>

        <!-- foot开始 -->
        <div class="foot text-c">
            <h1 class="m-t">和你的优秀同行一起，开启高效研发之旅</h1>
            <p>立即免费试用，或联系我们的专家，了解 ONES 如何帮助您的团队更进一步</p>
            <div>
                <button class="">免费使用</button>
                <button class="">免费使用</button>
            </div>
        </div>
    </div>
</template>
<script>
import SlickCarousel from "@/util/SlickCarousel.vue";
import axios from "axios";
export default {
    components: { SlickCarousel },
    data() {
        return {
            items: [],
            arr: [
                { id: 1, name: "Item 1" },
                { id: 2, name: "Item 2" },
            ],
            arr2: [
                { id: 1, name: "Item 1" },
                { id: 2, name: "Item 2" },
                { id: 3, name: "Item 3" },
            ],
            arr3: [
                { id: 1, name: "Item 1" },
                { id: 2, name: "Item 2" },
                { id: 3, name: "Item 3" },
                { id: 4, name: "Item 4" },
                { id: 5, name: "Item 5" },
                { id: 6, name: "Item 6" },
            ],
            activeId: null,
            isMout: false,
            ask: {},
            iconList: [],
        };
    },
    async created() {
        this.items = await this.getData();
        this.ask = await this.getAskAnswer();
        await this.getIconList();
    },
    methods: {
        show(e) {
            if (this.activeId == e.id) {
                this.activeId = null;
                return;
            }
            this.activeId = e.id;
        },
        async getData() {
            const { data } = await axios.get("/api/price/fshow");
            return data;
        },
        async getAskAnswer() {
            const { data } = await axios.get("/api/ask_answer/show");
            return data.find(e => e.title == "定价");
        },
        async getIconList() {
            axios.get("/api/partner/onehundredpartnerid").then(async ({ data }) => {
                let arr = [];
                if (data.length > 36) {
                    const sj = this.generateUniqueRandom(0, data.length - 1, 36);
                    sj.forEach(e => {
                        arr.push(data[e]);
                    });
                } else {
                    arr = data;
                }
                const { data: iconList } = await axios.get("/api/partner/onehundredpartner", { params: { id: arr.join(",") } });
                this.iconList = iconList;
            });
        },
        goDetails({ id, details, url, type }) {
            console.log(id);
            // 跳链接
            if (details == "" || details == "<p><br></p>" || details == null) {
                if (!url) return;
                if (url.indexOf("http") >= 0 || url.indexOf("https") >= 0) {
                    window.open(url);
                } else {
                    window.open("http://" + url);
                }
                return;
            }
            // 跳详情
            if (type == 1) {
                this.$router.push("/partner3Details?id=" + id);
            } else if (type == 2) {
                this.$router.push("/partner2Details?id=" + id);
            }
        },
        generateUniqueRandom(min, max, count) {
            if (max - min + 1 < count) {
                throw new Error("范围内不足以生成指定数量的不重复随机数");
            }

            let result = [];
            while (result.length < count) {
                let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                if (!result.includes(randomNum)) {
                    result.push(randomNum);
                }
            }
            return result;
        },
    },
    watch: {
        items(newVal) {
            if (newVal.length != 0) {
                this.$nextTick(() => {
                    this.isMout = true;
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.w {
    width: 1200px;
    margin: auto;
}
// 鼠标悬浮上移动画
.up {
    transform: translateY(0px);
    transition: 0.5s;
    &:hover {
        transform: translateY(-20px);
    }
}
.xu {
    width: 100%;
    h1 {
        text-align: left;
        max-width: 1200px;
        margin: 0 auto 30px;
    }
    & > div {
        width: 100%;
        max-width: 1200px;
        height: 120px;
        margin: 0 auto;
        display: flex;
        background: url(https://iv.okvu.cn/vugw/img/vu818xf.png) no-repeat center;
        background-size: auto 100%;
        text-align: left;
        padding: 30px 48px;
        font-size: 14px;
        color: white;
        & > div {
            & > p:nth-child(1) {
                font-size: 24px;
                margin-bottom: 10px;
            }
            a {
                color: white;
            }
        }
        & > div:nth-of-type(1) {
            width: 25%;
            border-right: 1px solid white;
            margin-right: 20px;
        }
        & > div:nth-of-type(2) {
            flex: 1;
        }
        & > div:nth-of-type(3) {
            flex: 1;
        }
        & > div:nth-of-type(4) {
            flex: 1;
        }
        & > div:nth-of-type(5) {
            width: 25%;
            text-align: center;
            margin-top: 12px;
            button {
                cursor: pointer;
                background: linear-gradient(90deg, #fffcf1, #ffe5d9);
                border: none;
                box-shadow: none;
                color: #8c201f;
                width: 70%;
                height: 36px;
            }
        }
    }
}

// bannerImgs
.bannerImgs {
    padding: 80px 0 60px;
    width: 100%;
    overflow: hidden;
    // cursor: none;
    & > div {
        height: 100px;
    }
    .sid {
        width: 180px;
        // width: calc(100vw / 12);
        height: 60px;
        margin: 20px 0;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        & > img {
            position: absolute;
            top: 0%;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            max-height: 100%;
        }
        & > p {
            position: absolute;
            top: 0%;
            left: 50%;
            transform: translateX(-50%);
            display: none;
            width: 100%;
            line-height: 60px;
            color: blue;
        }
        &:hover {
            box-shadow: 0px 0px 30px -20px black;
            & > img {
                display: none;
            }
            & > p {
                display: block;
            }
        }
    }
}

/* head头部 */
.head {
    /* height: 795px; */
    background: url("https://iv.okvu.cn/vugw/img/pricing-title-bg-1920.84d3ed3d.png") repeat-x;
    background-size: cover;
}
.head-one {
    width: 100%;
    padding: 100px 0;
    text-align: center;
}
.head-two {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 80px;
    text-align: left;
    /* background-color: aquamarine; */
}
.version {
    width: 380px;
    padding: 30px;
    background-color: white;
    // box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 21px -15px black;
    border-radius: 20px;
    // border-top: 8px solid white;
    margin-bottom: 30px;
    overflow: hidden;

    & > div {
        position: relative;
        .tip {
            position: absolute;
            padding: 4px 20px;
            right: -38px;
            top: -30px;
            background-color: #f5ebde;
            border-radius: 0px 20px 0px 20px;
            transform: skew(30deg);
            div {
                color: #a64d00;
                transform: skew(-30deg);
            }
        }
    }
}
.version-one {
    h3 {
        font-size: 24px;
    }
    button {
        width: 100%;
        height: 40px;
        text-align: center;
        padding: 0 20px;
        border: 1px solid #7781f1;
        background-color: rgba(179, 216, 255, 0);
        color: #7781f1;
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .loding {
            position: absolute;
            display: block;
            z-index: -1;
            width: 0%;
            height: 100%;
            top: 0px;
            left: 0px;
            border-radius: 20px;
            background-color: #7781f130;
            transition: 1s;
        }
    }
}
// .version-one div:nth-child(1) {
//     width: 100%;
//     height: 66px;
// }
.version-one > div:nth-child(2) {
    width: 100%;
    height: 110px;
    margin-top: 10px;
    .price {
        span:nth-of-type(1) {
            font-size: 16px;
            color: #ccc;
            // 删除线
            text-decoration: line-through;
            margin-right: 5px;
        }
        span:nth-of-type(2) {
            font-size: 12px;
            color: #7781f1;
        }
        span:nth-of-type(3) {
            font-size: 24px;
            color: #7781f1;
            font-weight: bold;
        }
    }
}
.version-two {
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #f0f0f2;
}
.m-t {
    margin-top: 10px;
}
.com {
    width: 100%;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    & > div {
        width: 22px;
        height: 22px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        & > img {
            position: absolute;
            top: -104px;
            left: -1003px;
        }
    }
}

/* arrange部署 */
.arrange {
    margin-top: 30px;
    padding-top: 100px;
    padding-bottom: 30px;
    /* background-color: antiquewhite; */
}
.text-c {
    text-align: center;
}
.arrange-com {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}
.arrange-d {
    display: flex;
    width: 585px;
    padding: 30px;
    box-shadow: 0px 8px 16px rgba(22, 23, 26, 0.05);
    border-radius: 5px;
    text-align: left;
}
.arrange-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}
.arrange-img img {
    width: 100px;
    height: 100px;
}
.arrange-ml {
    width: 385px;
    margin-left: 40px;
}
.arrange-bot {
    display: flex;
    margin-top: 30px;
}
.arrange-btn {
    border: none;
    color: blue;
    font-size: 16px;
    margin: auto;
    background-color: white;
    height: 22px;
    line-height: 22px;
    cursor: pointer;
}

/* service服务 */
.service {
    padding: 80px 0 60px;
    /* background-color: aqua; */
}
.service-com {
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
}
.service-d {
    width: 380px;
}
.service-d img {
    width: 380px;
    margin-bottom: 30px;
}
.service-d h3 {
    margin-bottom: 20px;
}

/* problem问题 */
.problem {
    padding: 100px 0 30px;
}
.problem-com {
    margin-top: 60px;
    .problem-d {
        color: gray;
        padding: 10px 0 10px;
        border-bottom: 1px solid #dcdcdc;
        height: 50px;
        transition: 0.5s;
        overflow: hidden;
        svg {
            transition: 0.5s;
        }
        .problem-d-t {
            cursor: pointer;
            &:hover {
                color: #828bf2 !important;
            }
        }
    }
    .problem-d:nth-last-child(1) {
        // border-bottom: none;
    }
}

.problem-d-t {
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
}
.problem-d-t div {
    font-size: 22px;
    font-weight: 600;
}
.problem-d-t svg {
    margin-top: auto;
}
.problem-d-b {
    text-align: left;
    animation: xla 1s;
    opacity: 0;
    white-space: nowrap;
    @keyframes xla {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
.active {
    height: 85px !important;
    svg {
        transform: rotate(180deg);
    }
    .problem-d-b {
        animation: xla1 1s;
        opacity: 1;
        @keyframes xla1 {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
}
/* authentication认证 */
.authentication {
    padding: 80px 0 60px;
    /* background-color: aqua; */
}
.authentication-com {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 200px;
    margin-top: 60px;
}
.authentication-d {
    width: 186px;
}

/* foot底部 */
.foot {
    width: 100%;
    // height: 373px;
    padding: 100px;
    background: url("https://iv.okvu.cn/vugw/img/footer-banner-bg.png");
    background-size: 140%;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    p {
        margin: 20px 0 30px;
    }
    button {
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        &:nth-of-type(1) {
            background-color: #0064ff;
            border: 1px solid #0064ff;
            color: white;
            transition: 0.5s;
            padding: 0 32px;
            margin-right: 20px;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }
        &:nth-of-type(2) {
            background-color: white;
            border: 1px solid #0064ff;
            color: #0064ff;
            transition: 0.5s;
            padding: 0 32px;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: rgba(179, 216, 255, 1);
            }
        }
    }
}
</style>
